import React, { useState, useEffect } from 'react';
import './fun.css'
import playAuio from '../func/playAuio';
import funM from '../asset/audios/fun.mp3';
import strawberry from '../asset/imgs/gender-strawberry.PNG';
import play from '../asset/imgs/wang/paly.PNG';
import board from '../func/connection/board';
import nowords from '../constant/noWords';
const cardArr = [];

const Fun = () => {

  playAuio.initAudio();

  const playPause = () => {
    playAuio.playPause(funM);
  }

  const [cards, setCards] = useState([]);
  const [inputVal, setInputVal] = useState('');


  const inputChange = (e) => {
    setInputVal(e.target.value);
  }

  const enterClearNeverWord = (e) => {
    if (e.key !== "Enter") return;
    if (!inputVal) return;
    // console.log(inputVal);
    let result = true;
    // nowords.forEach(x => {
    //   if (inputVal.includes(x)) result = false;
    // });
    if (inputVal.length === 300) return alert('300자 이하로 작성해주세요');
    if (!result) return alert('좋지 않은 용어는 자제해 주세요');
    cardArr.push({text: inputVal});
    board.addData(inputVal).then(result => {
      console.log(result);
      if (result.data.SUCCESS) {
        board.getData().then((data) => {
          if (data.data) {
            const reverse = data.data.DATA.reverse();
            setCards(reverse);
          }
        });
      }
    })
    // setCards(cardArr);
    setInputVal('');
  }

  const clickClearNeverWord = () => {
    if (!inputVal) return;
    let result = true;
    // nowords.forEach(x => {
    //   if (inputVal.includes(x)) result = false;
    // });
    if (inputVal.length === 300) return alert('300자 이하로 작성해주세요');
    if (!result) return alert('좋지 않은 용어는 자제해 주세요');
    cardArr.push({text: inputVal});
    board.addData(inputVal).then(result => {
      console.log(result);
      if (result.data.SUCCESS) {
        board.getData().then((data) => {
          if (data.data) {
            const reverse = data.data.DATA.reverse();
            setCards(reverse);
          }
        });
      }
    })
    // setCards(cardArr);
    setInputVal('');
  }

  useEffect(() => {
    
    board.getData().then((data) => {
      if (data.data) {
        const reverse = data.data.DATA.reverse();
        setCards(reverse);
      }
    });
  
  },[]);


  return (
    <div className="container-fluid p-0">
      
      <div className="row m-0 p-0" style={{background: '#FBF1EA'}}>
        <div className="col d-flex justify-content-center align-items-center">
          <div className="p-0 m-0 d-flex flex-column justify-content-center align-items-start town-title">
            <img src={play} onClick={playPause} style={{maxWidth: '200px', cursor: 'pointer'}}/>
            <p className="m-0 town-desc" 
              style={{fontFamily: 'notoL', fontSize: '30px', color: '#7F8FBD'}}>
              여러분이 생각하는 평화는?</p>
            <p className="m-0 town-name" 
              style={{fontFamily: 'gmkB', fontSize: '50px', color: '#7F8FBD'}}>
              평화마을</p>
          </div>
        </div>
        <div className="col">
          <div className="row d-flex flex-column justify-content-center align-items-center p-0">
            <img className="guitar" src={strawberry} style={{maxWidth: '500px'}}></img>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid m-0 p-0" style={{background: '#FBF1EA'}}>
        <div className="row p-0 m-0 fun-top">
          <div className="container d-flex flex-column align-items-center justify-content-md-center m-0 p-0">
            <div className="town-title p-0 m-0 fun-title-texts">
              <p className="m-0 town-desc" 
              style={{fontFamily: 'notoL', fontSize: '30px', color: '#7F8FBD'}}>
                여러분이 생각하는 평화는?</p>
              <p className="m-0 town-name" 
              style={{fontFamily: 'gmkB', fontSize: '50px', color: '#7F8FBD'}}>
                평화마을</p>
            </div>
          </div>
          <div className="row align-items-end justify-content-md-end p-0 fun-img">
            <img className="guitar" src={strawberry} style={{maxWidth: '500px'}}></img>
          </div>
        </div>
      </div> */}

      <div className="container-fluid d-flex flex-column align-items-center justify-content-md-center m-0 p-0 fun-two"
       style={{background: '#7F8FBD', minHeight: '400px'}}>
        <div className="row p-0 m-0 fun-q">
          <div className="col text-center pt-4">
            <p style={{fontFamily: 'gmkB', fontSize: '30px', color: 'white'}}>내가 생각하는 평화는?</p>
          </div>
        </div>

        <div className="input-group mb-3 w-50 fun-input">
          {/* <form className="input-group" onSubmit={enterClearNeverWord}> */}
            <input type="text" className="form-control" style={{minHeight: '50px'}} 
              onChange={inputChange} onKeyPress={enterClearNeverWord} value={inputVal}
              placeholder=" 상대방을 해칠 수 있는 말들은 조심해주세요" aria-label="Recipient's username" aria-describedby="basic-addon2" />
            <div className="input-group-append">
              <button className="input-group-text" id="basic-addon2"
                onClick={clickClearNeverWord} type="submit"
                style={{background: '#FBF1EA', fontFamily: 'gmkB', color: '625A9D'}}>입력</button>
            </div>
          {/* </form> */}
        </div>

      </div>

      <div className="container mt-5">
        <div class="row row-cols-1 row-cols-md-3 p-0 m-0 town-cards">

          {cards.map((card) => {
            return <div class="col mb-4">
            <div class="card h-100" style={{background: '#FBF1EA'}}>
              <div class="card-body h-100">
                <p class="card-text h-100" style={{fontFamily: 'notoL', fontSize: '15px', color: '#625A9D'}}>
                  {card.text}
                </p>
              </div>
            </div>
          </div>
          })}

        </div>
      </div>

    </div>
  );
}

export default Fun;
