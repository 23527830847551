import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../asset/imgs/logo.png';
import test from '../asset/test.jpg';

const defaultBtnColor = 'black';
const changeBtnColor = '#aba5d0';

const Nav = () => {
  const path = ['/animal', '/gender', '/war', '/fun', '/goodz'];
  const initBtnColors = () => {
    const result = [];
    for (let i=0; i < 6; i ++) {
      result.push(defaultBtnColor);
    }
    // console.log(result);
    return result;
  };
  const [btnColors, setBtnColors] = useState(initBtnColors());

  const initBtnList = ['동물권마을', '젠더마을', '전쟁마을', '즐길거리', '굿즈'].map((list, index) => {
    return <div className={`nav-item d-flex flex-column ${list === '굿즈'? '': 'mr-3'} btn-list-div-media`} key={list} >
      {/* <button type="button" className="btn btn-success mb-1 btn-list-media" style={{padding: '1.5px'}}></button> */}
      <Link className="nav-link p-0" 
          to={path[index]} 
          onClick={() => changeBtnColorFunc(index)} 
          style={{ color: btnColors[index], fontFamily: 'gmkB' }}> 
        { list === '굿즈' ? `굿\xa0\xa0\xa0즈\xa0\xa0`: list }  
        <span className="sr-only">(current)</span>
      </Link>
    </div>
  });
  

  const reInitColorl = () => {
    setBtnColors(initBtnColors());
  }
  const changeBtnColorFunc = (index) => {
    let newBtnColors = [];
    btnColors.forEach((color, num) => {
      if (index === num) {
        newBtnColors.push(changeBtnColor)
      }  else {
        newBtnColors.push(defaultBtnColor);
      }
    });
    // console.log(newBtnColors);
    setBtnColors(newBtnColors);
  }

  
  // to={path[index]}

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white nav-media h-100 m-0 p-0">

      <div class="container">
        <div class="row w-100 m-0 p-0">
          <div class="col nav-img-media">
            <a className="select-title-media">
              <Link to="/"><img className="p-0" alt='1' src={logo} style={{ maxWidth: '150px'}} 
              onClick={reInitColorl}></img></Link>
            </a>
          </div>

          <div class="col pt-3 mb-2 align-self-center">
            <div className="col select-btn-media">
              <button className="navbar-toggler" 
                type="button" data-toggle="collapse" data-target="#navbarSupportedContent" 
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>

              <div className="collapse navbar-collapse btn-list-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                </ul>
                <div className="form-inline my-2 my-lg-0 btn-section">
                  <ul className="navbar-nav btn-list-section">
                    {initBtnList}
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
  
    </nav>
  );
}

export default Nav;


