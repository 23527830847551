import React, { useState } from 'react';
import Modal from 'react-modal'
import fishPersion from '../asset/imgs/gender-fishperson.PNG';
import favorite from '../asset/imgs/gender-favorite.PNG';
import paly from '../asset/imgs/wang/paly.PNG';
import cream from '../asset/imgs/gender/cream.PNG';
import bilieve from '../asset/imgs/gender/bilieve.PNG';
import equal from '../asset/imgs/gender/equal.PNG';
import love from '../asset/imgs/gender/love.PNG';
import oliver from '../asset/imgs/gender/oliver.PNG';
import mainM from '../asset/audios/gener/intro.mp3';
import creamM from '../asset/audios/gener/2젠더-산딸기크림봉봉.mp3';
import oliverM from '../asset/audios/gener/2젠더-올리버튼은계집애래요.mp3';
import bilieveM from '../asset/audios/gener/2젠더-인어를 믿나요.mp3';
import equalM from '../asset/audios/gener/2젠더-평등한나라.mp3';
import loveM from '../asset/audios/gener/lovethispart.mp3';
import playAuio from '../func/playAuio';
import wangs from '../constant/wang';



const Gender = () => {
  playAuio.initAudio();

  const playPause = (linkURl) => {
    playAuio.playPause(linkURl);
  }


  const mpArr = [creamM, equalM, bilieveM, loveM, oliverM];

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});


  const openModal = (index) => {
    const imgArr = [cream, equal, bilieve, love, oliver];
    const simple = [
      '부드럽고 달콤한 산딸기 크림 봉봉! 누가 만들어왔고 어떻게 먹어왔을까? 달콤한 디저트의 역사를 통해 보는 젠더 역할 고정관념의 형성과 변화 과정! 우리는 평등해지고 있나요?',
      '에갈리나아에는 약 4천만명의 곰돌이가 산다. 분홍곰은 유모차를 밀고, 화장실 청소를. 파란곰은 넥타이를 매고, 자동차를 몰고, 큰 회사에 출근을. 분홍곰은 분홍곰이 해온 일만, 파란곰은 파란곰이 해온 일만 해야하는 걸까?',
      '인어를 보고 한 눈에 반한 줄리앙, 그는 인어가 되고 싶다. 자신을 인어처럼 꾸미던 줄리앙. 할머니는 줄리앙에게 자신의 목걸이를 내어주고 어디론가 같이 가자고 한다.',
      '그들은 서로 사랑하는 연인이자 가장 가까운 친구이다. 서로에게 둘도 없는 가장 소중한 사람들. 하지만 이 둘은 갈라서고야 마는데.. 그들은 왜 이별하게 되었을까?',
      '공놀이를 싫어하고 책읽기와 종이인형 놀이를 좋아하는 올리버 버튼, 그러다가 올리버 버튼은 탭댄스를 배우게 된다. 사람들은 책읽기, 종이인형 놀이, 탭댄스를 사랑하는 올리버 버튼을 왜 따가운 시선으로 보는 걸까…?'
    ];
    const bookData= [
      { title:'산 딸기 크림 봉봉', name: '에밀리젠킨스/소피블랙올', work: '씨드북' },
      { title:'평등한 나라', name: '에드가드봉크 / 요안나올레흐', work: '풀빛' },
      { title:'인어를 믿나요', name: '제시카러브', work: '웅진주니어' },
      { title:'i love this part', name: '틸리월든', work: '미디어창비' },
      { title:'올리버 버튼은 계집애래요', name: '토미드파올라', work: '문학과지성사' }
    ];
    const memory = [
      {one: '살살 녹아요 녹아!', sceen: '다같이 식탁에 앉아 산딸기 크림 봉봉을 먹는 장면'},
      {one: "원래 그렇게 해 왔어", sceen: '서로의 입장이 되는 파란곰과 분홍곰'},
      {one: "할머니….나도 인어인데,", sceen: '할머니가 줄리앙에게 자신의 목걸이를 내어주고 인어들의 축제에 데려가 주는 장면'},
      {one: '다행이다, 나만 그런거면 어쩔까 했는데', sceen: '둘의 첫 키스 장면'},
      {one: '올리버 버튼은 스타래요', sceen: '마침내 무대에서 공연하는 올리버 버튼의 모습'},
    ];
    const tag = [
      ['젠더차별','디저트','젠더고정관념','젠더평등','인종차별'],
      ['젠더차별', '평등','젠더권력','사회적약자','끈끈한바닥','남녀임금격차'],
      ['인어', '트렌스젠더','퀴어퍼레이드','그림체이뻐요'],
      ['퀴어', '레즈비언','첫사랑', '학생'],
      ['탭댄스', '젠더','편견을부수는']
    ];
    const feel = [
      [
        {name: '방울', desc: '수세기 동안 지속된 젠더,인종 차별속에서 20세기 후반이 되서야 문제점을 깨닫고 본격 적인 사회운동(제 2물결 페미니즘 운동, 흑인의 인권 운동, 북미 선주민 운동들..)이 시작된것을 보면 소외된 계층은 지배층 계층에 의해 오랫동안 집단의 폭력, 착취, 무력화, 주변화에 익숙해져 있어 당연하고 느낀것이 깨져 본격적인 사회문제로 대두 되기 시작한것을 알수 있다. 우리는 오랫동안 깨지지 않던 젠더의 문제들과 인종, 문화 차별의 역사를 벗어나야 한다.'},
        {name: '깁스', desc: '시대가 변화하면서 점점 평등에 가까워지는 모습이 보기 좋았다'},
        {name: '스티', desc: '책 마지막 부분쯤에 나온 것 처럼 남자들이 요리 하는 모습이 점점 더 늘어났으면한다.'},
      ],
      [
        {name: '방울', desc: '우리는 이 책을 읽고 집단의  정체성이 백인, 남성, 부르주아, 비장애인 이성애자에 맞춰 설정되어 있는것을 알수 있다. 따라서 우리는 유색인종, 여성, 동성애자등과 같은 소외된 사람 또한 공유하는 경험을 이해관계 토대로 하여 공동체 모두에게 진정으로 평등한 사회를 만들어 나가야 한다.'},
        {name: '깁스', desc: '우리 사회에도 곰 나라처럼 보이지 않는 곳에서 불평등이 많은것 같다'},
        {name: '스티', desc: '보통 여성들이 차별을 더 많이 받는데 여성들은 왜 차별을 받아야하는지 모르겠다.'},
      ],
      [
        {name: '방울', desc: '인어는 줄리앙의 진정한 정체성이라고 생각한다. 줄리앙을 보면 출생시의 지정성별과 다른 성별로 자신을 인식하고 행동하는 트랜스젠더가 생각난다. 할머니가 목걸이를 주는것은 줄리앙의 트렌지션 과정이라고 생각되고 인어들의 축제에 간 것은 퀴어퍼레이드에 간 줄리앙의 모습이라고 생각된다. 줄리앙과 같은 고민을 하는 사람들을 지지하고 응원한다.'},
        {name: '깁스', desc: '남자는 인어가 될수 없다는 고정관념을 깨는 책인것 같다'},
        {name: '스티', desc: '줄리앙은 남자인데 인어가 되고싶어하는것처럼 이 책도 고정관념이 좀 없는 것 같아 좋았다.'},
      ],
      [
        {name: '방울', desc: '둘이 헤어지게 된 계기가 사회적으로 동성애자를 배척하고 그들을  혐오하기 때문이라는 문제가 크게 차지 한다고 생각된다. 차이가 차별이 되는 현상들을 하나씩 바꾸어 나가야 한다. 다름을 틀렸다고 생각하는것을 고쳐야 한다. 성 소수자들이 그들의 선호와 정체성 그대로 존중 받아야 한다고 생각한다.'},
        {name: '깁스', desc: '성 소수자에 대한 편견이 많이 줄었지만, 아직 갈길이 먼 것 같다'},
        {name: '스티', desc: '모든 사람들이 나를 이해해줄 수 있는 사람을 만났으면 좋겠다.'},
      ],
      [
        {name: '방울', desc: '이 책을 보고 젠더는 인과적 사회구성이 아닌 형성적 사회구성이라고 생각했다. 모든 여성과 남성을 포괄하는 특징은 없고, 여성이 못하는것을 남성이라고 잘 할수 있다는 보장도 없지 않은가.“한 사람은 여성으로 태어나는것이 아니라 여성이 된다” 라는 말이 떠오른다.  우리는 영유아기 부터 소속된 집단내 활동이 개인을 구성하는것에 대해 의심하고 차이가 차별이 되는 부당한 논리에 저항해야 한다. '},
        {name: '깁스', desc: '동물들은 오래도록 봐왔던 숲의 풍경이 하루 아침에 고층 건물들로 바뀐 걸 보고 어떤 생각을 했을지 궁금하다. 보금자리를 빼앗겼다고 느꼈을까?'},
        {name: '스티', desc: '부모님이 고정관념이 좀 있으시긴 했지만 그래도 올리버만 봤을땐 고정관념이 없어서 좋았다.'},
      ]
    ];
    const initTitle = (<div className="align-content-start">
        <p className="pt-2" style={{fontFamily: 'gmkB', fontSize: '20px', color: '#56515E'}}>{bookData[index].title}</p>
        <p className="m-0" style={{fontFamily: 'notoL', color: '#56515E'}}>{bookData[index].name}</p>
        <p className="m-0" style={{fontFamily: 'notoL', color: '#56515E'}}>{bookData[index].work}</p>
      </div>);
    const intiTag = tag[index].map((tags, index) => {
      return (
        <p className="mb-0 pl-2" style={{fontFamily: 'gmkB', fontSize: '20px', color: '#56515E'}}>#{tags}</p>
      );
    });
    const initSimple = (<p className="mb-0" style={{fontFamily: 'notoL', fontSize: '15px', color: '#56515E'}}>{simple[index]}</p>);
    const initFeel = feel[index].map(data => {
      return (
        <div>
          <p className="mb-0 mb-1 pl-4 pr-4" style={{fontFamily: 'gmkB', fontSize: '18x', color: '#56515E'}}>{data.name}</p>
          <p className="mb-1 pl-4 pr-4" style={{fontFamily: 'gmkB', fontSize: '13px', color: '#56515E'}}>{data.desc}</p>
        </div>
      )
    })
    setModalData({
      audioIndex: index,
      srcLink: imgArr[index],
      title: initTitle,
      simple: initSimple,
      memory: memory[index],
      tag: intiTag,
      feel: initFeel
    });
    setIsOpen(true);
  }
 
  const afterOpenModal= () => {
    playAuio.initAudio();
    playAuio.playPause(mpArr[modalData.audioIndex]);
  }
 
  const closeModal = () =>{

    playAuio.playPause();
    setIsOpen(false);
  }


  const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '30%',
      height: '80%',
      outline: 'none',
      // marginRight: '-40%',
      transform: 'translate(-50%, -50%)',
      padding:  '0px',
      // boder: '1px solid #56515E !important'
    }
  };
 
  return (
    <div className="container-fluid p-0">

    {/* <img src={paly} className="playImg-gender" style={{maxWidth: '200px'}}/> */}

      <div className="row m-0 p-0" style={{background: 'white'}}>
        <div className="col d-flex justify-content-center align-items-center">
          <div className="p-0 m-0 d-flex flex-column justify-content-center align-items-start town-title">
            <img src={fishPersion} style={{maxWidth: '200px'}}/>
            <p className="m-0 town-desc" 
              style={{fontFamily: 'notoL', color: '#E07580', fontSize: '30px'}}>
              다름이 차별의 이유가되지 않는 평화</p>
            <p className="m-0 town-name" 
              style={{fontFamily: 'gmkB', color: '#E07580', fontSize: '50px'}}>
              젠더마을</p>
          </div>
        </div>
        <div className="col">
          <div className="row d-flex flex-column justify-content-center align-items-center p-0">
            <img className="guitar" src={favorite} style={{maxWidth: '500px'}}></img>
          </div>
        </div>
      </div>

      <div className="row m-0 p-0 town-middle" style={{background: '#56515E', minHeight: '400px'}}>
        <div className="col d-flex justify-content-center align-items-center" >
          <div className="container d-flex flex-column align-items-center justify-content-md-start m-0">
            <div>
              <p className="m-0 town-middle-title" 
                style={{fontFamily: 'gmkB', color: 'white', fontSize: '20px'}}>
                  젠더라는 말이 생소하실 분들이 있을거에요. <br />
                  젠더란 생물학적 성(sex)가 아닌 사회적 성을 말합니다.
              </p>
              <p className="m-0 town-middle-desc" 
                style={{fontFamily: 'notoL', color: 'white', fontSize: '16px'}}>
                이 마을은 젠더 문제에 관한 그림책으로 이루어진 마을이에요. <br />
                쉽고 재미있게 젠더문제들을 둘러보세요
              </p>
            </div>
          </div>
        </div>
        <div className="col town-none">
          <p></p>
        </div>
      </div>




      {/* <div className="container-fluid m-0 p-0" style={{background: 'white'}}>
        <div className="row p-0 m-0 town-title">
          <div className="container d-flex flex-column align-items-center justify-content-md-center m-0 p-0">
            <div className="town-title p-0 m-0">
              <img src={fishPersion} style={{maxWidth: '200px'}}/>
              <p className="m-0 town-desc" style={{fontFamily: 'notoL', fontSize: '30px', color: 'rgb(139 132 179)'}}>
                다름이 차별의 이유가되지 않는 평화</p>
              <p className="m-0 town-name" style={{fontFamily: 'gmkB', fontSize: '50px', color: '#625A9D'}}>젠더마을</p>
            </div>
          </div>
          <div className="row align-items-end justify-content-md-end p-0">
            <img className="guitar" src={favorite} style={{maxWidth: '500px'}}></img>
          </div>
        </div>
      </div>

      <div className="container-fluid m-0 p-0" style={{background: '#56515E'}}>
        <div className="row town-middle m-0 p-0" style={{minHeight: '420px'}}>
          <div className="container d-flex flex-column align-items-center justify-content-md-center m-0">
            <div>
              <p className="m-0 town-middle-title" style={{fontFamily: 'gmkB', color: '#F2C0BD', fontSize: '20px'}}>
                젠더라는 말이 생소하실 분들이 있을거에요. <br />
                젠더란 생물학적 성(sex)가 아닌 사회적 성을 말합니다.
                </p>
              <p className="m-0 town-middle-desc" style={{fontFamily: 'notoL', color: 'white', fontSize: '16px'}}>
                이 마을은 젠더 문제에 관한 그림책으로 이루어진 마을이에요. <br />
                쉽고 재미있게 젠더문제들을 둘러보세요
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="row mt-5 m-0 p-0">
        <div className="container d-flex flex-column align-items-center">
          <div className="row m-0 p-0">
            <img className="main-wang" onClick={() => { playPause(mainM) }} src={paly} style={{maxWidth: '200px', cursor: 'pointer'}}/>
          </div>

          <div className="row m-0 p-0">
            <div className="container d-flex align-items-center flex-column">
            <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '30px', color: '#E07580'}}>젠더마을 평화책</p>
            <div class="alert alert-danger mt-1" role="alert" 
              style={{padding: '1px 70px 1px 1px', borderColor: '#B5B9D1 !important', borderRadius: '20px'}}></div>
              <p className="w-50" style={{fontFamily: 'notoL', color: '#E07580'}}>
                모든 사람들이 자신이 꾸미고 싶은 대로 꾸며도 질타 받지 않는 날들,
                자신이 즐거워하는 일이 무엇이든 그것으로 인해 놀림 받지 않는 날들, 누군가를 사랑하는 마음과 달콤
                한 것을 먹고 싶은 마음이 누구에게나 동등하게 실현될 수 있는 날들 기대하며
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row m-0 p-0">
        
        <div class="container align-items-center">

          <div class="row w-50 m-0 mr-auto ml-auto mt-5 mb-5 town-book-top">
            <div class="col">
              <div className="container d-flex flex-column align-items-center justify-content-md-center m-0">
                <div className="d-flex flex-column align-items-center justify-content-md-center" 
                  style={{border: '1px solid rgb(139 132 179)', padding: '10px 40px 10px 40px', borderRadius: '20px'}}>
                  <img className="mt-3 mb-3" src={cream} style={{width: '150px' ,height: '180px'}} onClick={()=> openModal(0)}></img>
                  {/* <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#625A9D'}}>산딸기 크림 봉봉</p> */}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
              <div class="col">
                <div className="container d-flex flex-column align-items-end justify-content-md-center town-book">
                <div className="d-flex flex-column align-items-center justify-content-md-center" 
                  style={{border: '1px solid rgb(139 132 179)', padding: '10px 40px 10px 40px', borderRadius: '20px'}}>
                  <img className="mt-3 mb-3" src={equal} style={{width: '150px' ,height: '180px'}} onClick={()=> openModal(1)}></img>
                  {/* <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#625A9D'}}>평등한나라</p> */}
                </div>
                </div>
              </div>
              <div class="col">
                <div className="container d-flex flex-column align-items-start justify-content-md-center town-book">
                  <div className="d-flex flex-column align-items-center justify-content-md-center" 
                    style={{border: '1px solid rgb(139 132 179)', padding: '10px 40px 10px 40px', borderRadius: '20px'}}>
                    <img className="mt-3 mb-3" src={bilieve} style={{width: '150px', height: '180px'}}
                     onClick={()=> openModal(2)}></img>
                    {/* <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#625A9D'}}>인어를믿나요</p> */}
                  </div>
                </div>
              </div>
          </div>

          <div class="row mt-5 town-book-bottom">
              <div class="col">
                <div className="container d-flex flex-column align-items-end justify-content-md-center m-0 town-book">
                  <div className="d-flex flex-column align-items-center justify-content-md-center" 
                    style={{border: '1px solid rgb(139 132 179)', padding: '10px 40px 10px 40px', borderRadius: '20px'}}>
                    <img className="mt-3 mb-3" src={love} style={{width: '150px', height: '180px'}} 
                      onClick={()=> openModal(3)}></img>
                    {/* <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#625A9D'}}>i love this part</p> */}
                  </div>
                </div>
              </div>
              <div class="col">
                <div className="container d-flex flex-column align-items-start justify-content-md-center m-0 town-book">
                  <div className="d-flex flex-column align-items-center justify-content-md-center" 
                    style={{border: '1px solid rgb(139 132 179)', padding: '10px 40px 10px 40px', borderRadius: '20px'}}>
                    <img className="mt-3 mb-3" src={oliver} style={{width: '150px', height: '180px'}} 
                      onClick={()=> openModal(4)}></img>
                    {/* <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#625A9D'}}>올리버 버튼은 ...</p> */}
                  </div>
                </div>
              </div>
          </div>

        </div>

      </div>

      <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          
        >
 
          <div className="container-fluid p-0 m-0 w-100" role="alert" style={{width: '300px'}}>

            <div class="alert alert-dark p-2 m-0" role="alert" style={{background: '#56515E'}}>
            </div>

            <div className="container p-3">

              <div class="row p-0 m-0">
                <div className="col">
                  <img className="col h-100" src={modalData.srcLink} 
                  ></img>
                </div>
                <div className="col d-flex algin-item-start">
                  {modalData.title}
                </div>
                {/* <div className="col p-0 m-0 d-flex  align-items-center justify-content-center">
                  <img className="col pop-img" src={modalData.srcLink} 
                  style={{width: '150px' ,height: '180px'}}
                  ></img>
                </div>
                <div className="col p-0 m-0 d-flex  justify-content-start">
                  {modalData.title}
                </div> */}
                {/* <img className="col-4 pop-img" src={modalData.srcLink} 
                  style={{MaxWidth: '100px', height: '180px'}}></img>
                {modalData.title} */}
              </div>

              <div class="row mt-2 mb-0 pb-0 pl-3 pr-3">
                <div className="col d-flex flex-wrap">
                  {modalData.tag}
                </div>
              </div>

              <div class="row mt-3 pt-0 pl-4 pr-4">
                <div className="col">
                  <p className="mb-0" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#56515E'}}>간략한 한줄 소개</p>
                </div>
              </div>

              <div class="row mt-0 pt-0 pl-4 pr-4">
                <div className="col">
                  {modalData.simple}
                </div>
              </div>

              <div class="row mt-3 pt-0 pl-4 pr-4">
                <div className="col">
                  <p className="mb-0" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#56515E'}}>기억에남는 한줄</p>
                </div>
              </div>

              <div class="row mt-0 pt-0 pl-4 pr-4">
                <div className="col">
                  <p className="mb-0" style={{fontFamily: 'notoL', fontSize: '15px', color: '#56515E'}}>
                    {modalData?.memory?.one}
                  </p>
                </div>
              </div>

              <div class="row mt-3 pt-0 pl-4 pr-4">
                <div className="col">
                  <p className="mb-0" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#56515E'}}>기억에남는 장면</p>
                </div>
              </div>

              <div class="row mt-0 pt-0 pl-2 mb-3 pl-4 pr-4">
                <div className="col">
                  <p className="mb-0" style={{fontFamily: 'notoL', fontSize: '15px', color: '#56515E'}}>
                    {modalData?.memory?.sceen}
                  </p>
                </div>
              </div>

              {/* <div class="row mt-3 pt-0">
                <div className="col-1"></div>
                <div className="col-10">
                  <p className="mb-0" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#56515E'}}>더재미있게 읽는 TIP</p>
                </div>
              </div> */}


            </div>

            <div class="row m-0 p-0 d-flex flex-column" style={{minHeight: '200px'}}>
              <div className="col d-flex align-items-center justify-content-center pt-3">
                <img className="col" src={wangs.data[Math.floor(Math.random() * wangs.data.length)]} style={{ maxWidth: '200px', height: 'auto'}}></img>
              </div>
              <div className="col d-flex align-items-end justify-content-center">
                <p className="pt-2" style={{fontFamily: 'gmkB', fontSize: '20px', color: '#56515E'}}>왕왕이 감상평</p>
              </div>
            </div>

              <div className="container-fluid p-0 m-0 w-100" style={{background: '#FBF1EA'}}>
                <div class="row m-0 p-0 mt-4 pt-4 pb-4">
                  <div className="col">
                    {modalData.feel}
                  </div>
                </div>
              </div>
          </div>

        </Modal>

    </div>
  );
}

export default Gender;
