// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./asset/font/GmarketSansOTF/GmarketSansBold.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./asset/font/GmarketSansOTF/GmarketSansLight.otf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./asset/font/GmarketSansOTF/GmarketSansMedium.otf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./asset/font/NotoSansCJKkr-Light.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "html {\n  background-size: cover;\n}\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n@font-face {\n  font-family: 'gmkB';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")\n}\n@font-face {\n  font-family: 'gmkL';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")\n}\n@font-face {\n  font-family: 'gmkM';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")\n}\n@font-face {\n  font-family: 'notoL';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")\n}", ""]);
// Exports
module.exports = exports;
