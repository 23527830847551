
  // ec2-3-34-200-91.ap-northeast-2.compute.amazonaws.com
export default {
  SERVER_IP: 'peace-api.haja.net',
  END_POINTS: {
    //BOARD: 'http://ec2-3-35-149-82.ap-northeast-2.compute.amazonaws.com:5000/board',
    //NUM: 'http://ec2-3-35-149-82.ap-northeast-2.compute.amazonaws.com:5000/num',
    //NUM2: 'http://localhost:5000/num'
    BOARD: 'https://peace-api.haja.net/board',
    NUM: 'https://peace-api.haja.net/num',
    NUM2: 'https://peace-api.haja.net/num'
    // BOARD: 'http://localhost:5000/board',
  }
}