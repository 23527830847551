
import sum1 from '../asset/imgs/goodz/sum/엽서섬네일 (1).PNG';
import sum2 from '../asset/imgs/goodz/sum//엽서섬네일 (2).PNG';
import sum3 from '../asset/imgs/goodz/sum/엽서섬네일 (3).PNG';
import sum4 from '../asset/imgs/goodz/sum/엽서섬네일 (4).PNG';
import sum5 from '../asset/imgs/goodz/sum/엽서섬네일 (5).PNG';
import sum6 from '../asset/imgs/goodz/sum/엽서섬네일 (6).PNG';
import sum7 from '../asset/imgs/goodz/sum/엽서섬네일 (7).PNG';
import sum8 from '../asset/imgs/goodz/sum/엽서섬네일 (8).PNG';
import sum9 from '../asset/imgs/goodz/sum/엽서섬네일 (9).PNG';
import sum10 from '../asset/imgs/goodz/sum/엽서섬네일 (10).PNG';
import sum11 from '../asset/imgs/goodz/sum/엽서섬네일 (11).PNG';
import sum12 from '../asset/imgs/goodz/sum/엽서섬네일 (12).PNG';
import sum13 from '../asset/imgs/goodz/sum/엽서섬네일 (13).PNG';
import bro from '../asset/imgs/goodz/sum/브로슈어 썸네일.png';
import call from '../asset/imgs/goodz/sum/컬러링 썸네일.png';

import down1 from '../asset/imgs/goodz/downGoodz/엽서 (1).pdf';
import down2 from '../asset/imgs/goodz/downGoodz/엽서 (2).pdf';
import down3 from '../asset/imgs/goodz/downGoodz/엽서 (3).pdf';
import down4 from '../asset/imgs/goodz/downGoodz/엽서 (4).pdf';
import down5 from '../asset/imgs/goodz/downGoodz/엽서 (5).pdf';
import down6 from '../asset/imgs/goodz/downGoodz/엽서 (6).pdf';
import down7 from '../asset/imgs/goodz/downGoodz/엽서 (7).pdf';
import down8 from '../asset/imgs/goodz/downGoodz/엽서 (8).pdf';
import down9 from '../asset/imgs/goodz/downGoodz/엽서 (9).pdf';
import down10 from '../asset/imgs/goodz/downGoodz/엽서 (10).pdf';
import down11 from '../asset/imgs/goodz/downGoodz/엽서 (11).pdf';
import down12 from '../asset/imgs/goodz/downGoodz/엽서 (12).pdf';
import down13 from '../asset/imgs/goodz/downGoodz/통합본.pdf';
import down14 from '../asset/imgs/goodz/downGoodz/브로슈어 다운로드용.pdf';
import down15 from '../asset/imgs/goodz/downGoodz/평화지도_스케치(컬러링).jpg';





export default {
  sum: [sum1, sum2, sum3, sum4, sum5, sum6, sum7, sum8, sum9, sum10, sum11, sum12, sum13, bro, call ],
  down: [down1, down2, down3, down4, down5, down6, down7, down8, down9, down10, down11, down12, down13, down14, down15]
}