import React, { useState } from 'react';
import Modal from 'react-modal'
import './town.css';
import daramgi from '../asset/imgs/animal-daramgi.PNG';
import guitar from '../asset/imgs/animal-guitar.PNG';
import paly from '../asset/imgs/wang/paly.PNG';
import home from '../asset/imgs/animal/home.PNG';
import look from '../asset/imgs/animal/look.PNG';
import pig from '../asset/imgs/animal/pig.PNG';
import strong from '../asset/imgs/animal/strong.PNG';
import way from '../asset/imgs/animal/way.PNG';
import mainM from '../asset/audios/animal/3동물권-1마을소개.mp3';
import pigM from '../asset/audios/animal/3동물권-돼지이야기.mp3';
import wayM from '../asset/audios/animal/3동물권-생태통로.mp3';
import lookM from '../asset/audios/animal/3동물권-서로를보다.mp3';
import strongM from '../asset/audios/animal/3동물권-아주아주센모기약이발명된다면.mp3';
import homeM from '../asset/audios/animal/3동물권-우리,집.mp3';
import playAuio from '../func/playAuio';
import wangs from '../constant/wang';





const Animal = () => {

  playAuio.initAudio();

  const playPause = (linkURl) => {
    playAuio.playPause(linkURl);
  }
  
  const mpArr = [homeM, lookM, pigM, wayM, strongM];

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const openModal = (index) => {
    const imgArr = [home, look, pig, way, strong];
    
    const simple = [
      '동물원과 같은 인공적인 동물들의 보금자리, 그리고 동물들의 진짜 보금자리인 자연에 대한 이야기',
      '우리 안에 갇힌 동물의 시선으로 보는 대화내용이 자신이 자기다운 삶을 살고있는지, 동물들의 아픔을 생각하고 있는지 다시 생각하게 되는 내용을 담은 책 입니다.',
      '실제로 대한민국을 덮쳤던 구제역 사태의 내용을 담았다. 좁디좁은 사육틀에 갇혀 살던 돼지들이 전염병이 퍼지자 산채로 구덩이에 파묻혀지는 내용이 담겨져 있다. 돼지들이 사육틀에서 사는 게 옳을까? 전염병은 인간으로부터 시작된 것이 아닐까?',
      '어느날 갑자기 인간들이 만든 도로! 제각기 날아다니고 뛰어다니고 걸어다니고 기어다니는 동물들에게 도로는 어떤 존재일까? 생태통로는 무엇일까?',
      '똑똑한 사람들이 모여 개발한 초강력 모기약을 모기싫어섬 주민들에게 나누어 주었는데.. 이후 모기싫어섬에는 변화가 찾아왔다. 그 변화를 겪은 모기싫어섬 사람들은 무엇을 깨닫게 되었을까?'
    ];
    const bookData= [
      { title:'우리집', name: '진주/진경', work: '고래뱃속' },
      { title:'서로를 보다', name: '이유정/윤여림', work: '낮은산' },
      { title:'돼지 이야기', name: '유리', work: '이야기꽃' },
      { title:'생태통로', name: '안은진/김황', work: '논장' },
      { title:'아주아주 쎈 모기약이 발명된다면', name: '곽민수', work: '숨쉬는책공장' }
    ];
    const memory = [
      {one: '세상에서 가장 좋은 우리집입니다.', sceen: '2개의 우리집'},
      {one: "알고 있니? 콘도르라는 말은 잉카 말로 '어떤 것에도 얽매이지 않는 자유'라고 해", sceen: '마지막 인간과 동물이 서로를 바라보고 있는 장면'},
      {one: "그 해 겨울, 332만 마리 돼지들이 마지막이 되어 버린 첫 외출을 나섰습니다", sceen: '돼지가 구덩이에 파묻혀지면서도 자신의 아이를 찾아 헤매던 모습'},
      {one: '그리운 아내와 처음보는 아이들이 기다리는 숲을 향해 ! 높은 나무가 없으면 날 수 없는데...', sceen: '하늘다람쥐가 날아다니는 장면, 로드킬 당한 장면'},
      {one: '하지만 아무도 다시는 초강력 모기약을 뿌리지 않아', sceen: '고양이가 쥐를 잡아먹는 장면, 인간이 모기약을 뿌리지 않는 장면'},
    ];
    const tag = [
      ['동물','집','동물권','자연','인간','고향','숲'],
      ['동물원', '동물','동물권','아픔'],
      ['실제사례', '구제역사태','트라우마','생명과공감'],
      ['생태계', '자연','동물'],
      ['생태계', '생태피라미드','먹이사슬','모기약']
    ];
    const feel = [
      [
        {name: '뽀', desc: '집이 두개이다, 자연의 집으로 돌아가고 싶을 것 같다.. 너무 안타깝다..ㅠ'},
        {name: '룬', desc: '동물들은 생명을 위협받을 수 있다는 두려움을 감수하고도 다시 그들의 고향으로 돌아가고 싶어할지 궁금하다.'},
        {name: '하지', desc: '동물들이 화려하고 멋진 집을 좋아하는 것처럼 보였지만, 사실은 숲 자기고향을 그리워 하고 있는게 느껴졌다.'},
      ],
      [
        {name: '뽀', desc: '동물의 두 가지의(?)모습..행복하게 본모습을 찾으며 살았으면 좋겠다'},
        {name: '룬', desc: '자신의 본성을 억압 당한 채 좁고도 좁은 우리 안에서만 지내는 동물들의 모습이 안타깝게 다가왔다.'},
        {name: '하지', desc: '인간도 동물인데, 왜 인간이 힘을 더 가졌다는 이유만으로 자유롭게 살아갈까?'},
      ],
      [
        {name: '뽀', desc: '다음생은 더 행복한 돼지로 태어났으면 좋겠다'},
        {name: '룬', desc: '돼지도 다른 동물처럼 넓은 목초지에서 자유롭게 살아갈 수 있는 날이 오기를 바란다'},
        {name: '하지', desc: '돼지들의 숨겨진 아픔과 어두운 면을 알게 됬었는데 인간을 위해 갇혀 사는 돼지들이 많이 불쌍해보였다'},
      ],
      [
        {name: '뽀', desc: '인간이 만든 인간의 편리함..동물에게는 무기가 될수도 있다는걸 느꼈다'},
        {name: '룬', desc: '동물들은 오래도록 봐왔던 숲의 풍경이 하루 아침에 고층 건물들로 바뀐 걸 보고 어떤 생각을 했을지 궁금하다. 보금자리를 빼앗겼다고 느꼈을까?'},
        {name: '하지', desc: '동물들을 위해 만들었지만 과연 저것들이 동물의 편의를 생각해서 만든걸까 ?'},
      ],
      [
        {name: '뽀', desc: '인간이 만든 인간의 편리함..동물에게는 무기가 될수도 있다는걸 느꼈다'},
        {name: '룬', desc: '동물들은 오래도록 봐왔던 숲의 풍경이 하루 아침에 고층 건물들로 바뀐 걸 보고 어떤 생각을 했을지 궁금하다. 보금자리를 빼앗겼다고 느꼈을까?'},
        {name: '하지', desc: '인간을 위한 발명품이 생각보다 심각하게 생태계를 파괴하는 것 같다'},
      ]
    ];
    const initTitle = (<div className="align-content-start">
        <p className="pt-2" style={{fontFamily: 'gmkB', fontSize: '20px', color: '#56515E'}}>{bookData[index].title}</p>
        <p className="m-0" style={{fontFamily: 'notoL', color: '#56515E'}}>{bookData[index].name}</p>
        <p className="m-0" style={{fontFamily: 'notoL', color: '#56515E'}}>{bookData[index].work}</p>
      </div>);
      // console.log(tag[index]);
    const intiTag = tag[index].map((tags, index) => {
      return (
        <p className="mb-0 pl-2" style={{fontFamily: 'gmkB', fontSize: '20px', color: '#56515E'}}>#{tags}</p>
      );
    });
    const initSimple = (<p className="mb-0" style={{fontFamily: 'notoL', fontSize: '15px', color: '#56515E'}}>{simple[index]}</p>);
    const initFeel = feel[index].map(data => {
      return (
        <div>
          <p className="mb-0 mb-1 pl-4 pr-4" style={{fontFamily: 'gmkB', fontSize: '18x', color: '#56515E'}}>{data.name}</p>
          <p className="mb-1 pl-4 pr-4" style={{fontFamily: 'gmkB', fontSize: '13px', color: '#56515E'}}>{data.desc}</p>
        </div>
      )
    })
    setModalData({
      audioIndex: index,
      srcLink: imgArr[index],
      title: initTitle,
      simple: initSimple,
      memory: memory[index],
      tag: intiTag,
      feel: initFeel
    });
    setIsOpen(true);
  }
 
  const afterOpenModal= () => {
    playAuio.initAudio();
    playAuio.playPause(mpArr[modalData.audioIndex]);
  }
 
  const closeModal = () =>{

    playAuio.playPause();
    setIsOpen(false);
  }

  const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '30%',
      height: '80%',
      outline: 'none',
      // marginRight: '-40%',
      transform: 'translate(-50%, -50%)',
      padding:  '0px',
      // boder: '1px solid #56515E !important'
    }
  };
 

  return (
    <div className="container-fluid p-0">

      {/* <img src={paly} className="playImg-animal" style={{maxWidth: '200px'}}/> */}

      <div className="row m-0 p-0" style={{background: '#FBF1EA'}}>
        <div className="col d-flex justify-content-center align-items-center">
          <div className="p-0 m-0 d-flex flex-column justify-content-center align-items-start town-title">
            <img src={daramgi} style={{maxWidth: '200px'}}/>
            <p className="m-0 town-desc" 
              style={{fontFamily: 'notoL', fontSize: '30px', color: 'rgb(139 132 179)'}}>
              인간만의 평화가 아닌 모두의 평화</p>
            <p className="m-0 town-name" 
              style={{fontFamily: 'gmkB', fontSize: '50px', color: '#625A9D'}}>동물권마을</p>
          </div>
        </div>
        <div className="col">
          <div className="row d-flex flex-column justify-content-center align-items-center p-0">
            <img className="guitar" src={guitar} style={{maxWidth: '500px'}}></img>
          </div>
        </div>
      </div>

      <div className="row m-0 p-0 town-middle" style={{background: '#56515E', minHeight: '400px'}}>
        <div className="col d-flex justify-content-center align-items-center" >
          <div className="container d-flex flex-column align-items-center justify-content-md-start m-0">
            <div>
              <p className="m-0 town-middle-title" style={{fontFamily: 'gmkB', color: '#F2C0BD', fontSize: '20px'}}>
                지구는 인간을 제외하고도 수많은 동물들이 살고있어요
                </p>
              <p className="m-0 town-middle-desc" style={{fontFamily: 'notoL', color: 'white', fontSize: '16px'}}>
                이 마을은 인간이 동물에게 끼치는 피해들을 <br /> 동물의 입장에서 작성한 책들로 이루어져 있어요. <br />
                가깝고도 멀리 살고 있는 동물들의 이야기를 들어보세요.
              </p>
            </div>
          </div>
        </div>
        <div className="col town-none">
          <p></p>
        </div>
      </div>

      {/* <div className="container-fluid m-0 p-0" style={{background: '#FBF1EA'}}>
        <div className="row p-0 m-0 town-title">
          <div className="container d-flex flex-column align-items-center justify-content-md-center m-0 p-0">
            <div className="town-title p-0 m-0">
              <img src={daramgi} style={{maxWidth: '200px'}}/>
              <p className="m-0 town-desc" style={{fontFamily: 'notoL', fontSize: '30px', color: 'rgb(139 132 179)'}}>
                인간만의 평화가 아닌 모두의 평화</p>
              <p className="m-0 town-name" style={{fontFamily: 'gmkB', fontSize: '50px', color: '#625A9D'}}>동물권마을</p>
            </div>
          </div>
          <div className="row align-items-end justify-content-md-end p-0">
            <img className="guitar" src={guitar} style={{maxWidth: '500px'}}></img>
          </div>
        </div>
      </div> */}

      {/* <div className="container-fluid m-0 p-0" style={{background: '#56515E'}}>
        <div className="row town-middle m-0 p-0" style={{minHeight: '420px'}}>
          <div className="container d-flex flex-column align-items-center justify-content-md-center m-0">
            <div>
              <p className="m-0 town-middle-title" style={{fontFamily: 'gmkB', color: '#F2C0BD', fontSize: '20px'}}>
                지구는 인간을 제외하고도 수많은 동물들이 살고있어요
                </p>
              <p className="m-0 town-middle-desc" style={{fontFamily: 'notoL', color: 'white', fontSize: '16px'}}>
                이 마을은 인간이 동물에게 끼치는 피해들을 <br /> 동물의 입장에서 작성한 책들로 이루어져 있어요. <br />
                가깝고도 멀리 살고 있는 동물들의 이야기를 들어보세요.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div className="row mt-5 m-0 p-0">
        <div className="container d-flex flex-column align-items-center">
          <div className="row m-0 p-0">
            <img className="main-wang" 
              onClick={() => { playPause(mainM)}} src={paly} style={{maxWidth: '200px', cursor: 'pointer'}}/>
          </div>

          <div className="row m-0 p-0">
            <div className="container d-flex align-items-center flex-column">
            <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '30px', color: '#625A9D'}}>동물권마을 평화책</p>
            <div class="alert alert-danger mt-1" role="alert" 
              style={{padding: '1px 70px 1px 1px', borderColor: '#B5B9D1 !important', borderRadius: '20px'}}></div>
              <p className="w-50" style={{fontFamily: 'notoL', color: 'rgb(139 132 179)'}}>
                동물들과 함께 살아가기 위해선 어떤 노력들을 해야 할까요? 왜 노력을 해야 할까요? 동물권 마을의 이야기들을 만나며 생각해보실 수 있으면 좋겠습니다.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row m-0 p-0">
        
        <div class="container align-items-center">

          <div class="row w-50 m-0 mr-auto ml-auto mt-5 mb-5 town-book-top">
            <div class="col">
              <div className="container d-flex flex-column align-items-center justify-content-md-center m-0">
                <div className="d-flex flex-column align-items-center justify-content-md-center" 
                  style={{border: '1px solid rgb(139 132 179)', padding: '10px 40px 10px 40px', borderRadius: '20px'}}>
                  <img className="mt-3 mb-3" src={home} style={{width: '150px' ,height: '180px'}} onClick={()=> openModal(0)}></img>
                  {/* <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#625A9D'}}>우리 집</p> */}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
              <div class="col">
                <div className="container d-flex flex-column align-items-end justify-content-md-center town-book">
                <div className="d-flex flex-column align-items-center justify-content-md-center" 
                  style={{border: '1px solid rgb(139 132 179)', padding: '10px 40px 10px 40px', borderRadius: '20px'}}>
                  <img className="mt-3 mb-3" src={look} style={{width: '150px' ,height: '180px'}} onClick={()=> openModal(1)}></img>
                  {/* <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#625A9D'}}>서로를보다</p> */}
                </div>
                </div>
              </div>
              <div class="col">
                <div className="container d-flex flex-column align-items-start justify-content-md-center town-book">
                  <div className="d-flex flex-column align-items-center justify-content-md-center" 
                    style={{border: '1px solid rgb(139 132 179)', padding: '10px 40px 10px 40px', borderRadius: '20px'}}>
                    <img className="mt-3 mb-3" src={pig} style={{width: '150px', height: '180px'}}
                     onClick={()=> openModal(2)}></img>
                    {/* <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#625A9D'}}> 
                      <br /> 
                      <br />
                      돼지이야기<br /><br />
                    </p> */}
                  </div>
                </div>
              </div>
          </div>

          <div class="row mt-5 town-book-bottom">
              <div class="col">
                <div className="container d-flex flex-column align-items-end justify-content-md-center m-0 town-book">
                  <div className="d-flex flex-column align-items-center justify-content-md-center" 
                    style={{border: '1px solid rgb(139 132 179)', padding: '10px 40px 10px 40px', borderRadius: '20px'}}>
                    <img className="mt-3 mb-3" src={way} style={{width: '150px', height: '180px'}} 
                      onClick={()=> openModal(3)}></img>
                    {/* <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#625A9D'}}>생태통로</p> */}
                  </div>
                </div>
              </div>
              <div class="col">
                <div className="container d-flex flex-column align-items-start justify-content-md-center m-0 town-book">
                  <div className="d-flex flex-column align-items-center justify-content-md-center" 
                    style={{border: '1px solid rgb(139 132 179)', padding: '10px 40px 10px 40px', borderRadius: '20px'}}>
                    <img className="mt-3 mb-3" src={strong} style={{width: '150px', height: '180px'}} 
                      onClick={()=> openModal(4)}></img>
                    {/* <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#625A9D'}}>
                      <br />
                      아주아주 쎈 <br />
                      모기약이 발명된다면?
                    </p> */}
                  </div>
                </div>
              </div>
          </div>

        </div>

      </div>

      <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          
        >
 
          <div className="container-fluid p-0 m-0 w-100" role="alert" style={{width: '300px'}}>

            <div class="alert alert-dark p-2 m-0" role="alert" style={{background: '#56515E'}}>
            </div>

            <div className="container p-3">

              <div class="row p-0 m-0">
                <div className="col">
                  <img className="col h-100" src={modalData.srcLink} 
                  ></img>
                </div>
                <div className="col d-flex algin-item-start">
                  {modalData.title}
                </div>
                {/* <div className="col p-0 m-0 d-flex  align-items-center justify-content-center">
                  <img className="col pop-img" src={modalData.srcLink} 
                  style={{width: '150px' ,height: '180px'}}
                  ></img>
                </div>
                <div className="col p-0 m-0 d-flex  justify-content-start">
                  {modalData.title}
                </div> */}
                {/* <img className="col-4 pop-img" src={modalData.srcLink} 
                  style={{MaxWidth: '100px', height: '180px'}}></img>
                {modalData.title} */}
              </div>

              <div class="row mt-2 mb-0 pb-0 pl-3 pr-3">
                <div className="col d-flex flex-wrap">
                  {modalData.tag}
                </div>
              </div>

              <div class="row mt-3 pt-0 pl-4 pr-4">
                <div className="col">
                  <p className="mb-0" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#56515E'}}>간략한 한줄 소개</p>
                </div>
              </div>

              <div class="row mt-0 pt-0 pl-4 pr-4">
                <div className="col">
                  {modalData.simple}
                </div>
              </div>

              <div class="row mt-3 pt-0 pl-4 pr-4">
                <div className="col">
                  <p className="mb-0" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#56515E'}}>기억에남는 한줄</p>
                </div>
              </div>

              <div class="row mt-0 pt-0 pl-4 pr-4">
                <div className="col">
                  <p className="mb-0" style={{fontFamily: 'notoL', fontSize: '15px', color: '#56515E'}}>
                    {modalData?.memory?.one}
                  </p>
                </div>
              </div>

              <div class="row mt-3 pt-0 pl-4 pr-4">
                <div className="col">
                  <p className="mb-0" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#56515E'}}>기억에남는 장면</p>
                </div>
              </div>

              <div class="row mt-0 pt-0 pl-2 mb-3 pl-4 pr-4">
                <div className="col">
                  <p className="mb-0" style={{fontFamily: 'notoL', fontSize: '15px', color: '#56515E'}}>
                    {modalData?.memory?.sceen}
                  </p>
                </div>
              </div>

              {/* <div class="row mt-3 pt-0">
                <div className="col-1"></div>
                <div className="col-10">
                  <p className="mb-0" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#56515E'}}>더재미있게 읽는 TIP</p>
                </div>
              </div> */}


            </div>

            <div class="row m-0 p-0 d-flex flex-column" style={{minHeight: '200px'}}>
              <div className="col d-flex align-items-center justify-content-center pt-3">
                <img className="col" src={wangs.data[Math.floor(Math.random() * wangs.data.length)]} style={{ maxWidth: '200px', height: 'auto'}}></img>
              </div>
              <div className="col d-flex align-items-end justify-content-center">
                <p className="pt-2" style={{fontFamily: 'gmkB', fontSize: '20px', color: '#56515E'}}>왕왕이 감상평</p>
              </div>
            </div>

              <div className="container-fluid p-0 m-0 w-100" style={{background: '#FBF1EA'}}>
                <div class="row m-0 p-0 mt-4 pt-4 pb-4">
                  <div className="col">
                    {modalData.feel}
                  </div>
                </div>
              </div>
          </div>

        </Modal>

    </div>
  );
}

export default Animal;
