import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import goodzM from '../asset/audios/goodzM.mp3';
import love from '../asset/imgs/wang/love.PNG';
import paly from '../asset/imgs/wang/paly.PNG';
import bling from '../asset/imgs/wang/bling.PNG';
import cry from '../asset/imgs/wang/cry.PNG';
import mmm from '../asset/imgs/wang/mmm.PNG';
import playAuio from '../func/playAuio';
import goodzImg from '../constant/goodzImg';
import download from '../asset/download.png';


const Goodz = () => {
  playAuio.initAudio();

  const playPause = () => {
    playAuio.playPause(goodzM);
  }

  // <Link type="button" class="btn btn-success" to={test} target="_blank" download="goodz">다운로드</Link>
  return (
    <div className="container-fluid p-0">
      
      <div className="row m-0 p-0" style={{background: '#FBF1EA', minHeight: '650px'}}>
        <div className="col d-flex justify-content-center align-items-center">
          <div className="p-0 m-0 d-flex flex-column justify-content-center align-items-center town-title">
            <div className="p-0 m-0 d-flex flex-row justify-content-center align-items-center goodz-imgs">
              <img className="goodz-imgs" src={love} style={{maxWidth: '200px'}}/>
              <img className="goodz-imgs" src={mmm} style={{maxWidth: '200px'}}/>
              <img className="goodz-imgs-play" onClick={playPause} src={paly} style={{maxWidth: '200px', cursor: 'pointer'}}/>
              <img className="goodz-imgs" src={bling} style={{maxWidth: '200px'}}/>
              <img className="goodz-imgs" src={cry} style={{maxWidth: '200px'}}/>
            </div>
            <p className="m-0 town-desc" 
              style={{fontFamily: 'notoL', fontSize: '30px', color: '#7F8FBD'}}>
              평화지도 마음 속 저장</p>
            <p className="m-0 town-name" 
              style={{fontFamily: 'gmkB', fontSize: '50px', color: '#7F8FBD'}}>
              굿즈</p>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div class="row row-cols-1 row-cols-md-3 p-0 m-0">
          {goodzImg.sum.map((goodz, index) => {
            return (
              <div class="col mb-4">
                <div class="card h-100" style={{background: '#FBF1EA', border: '1px solid #373543'}}>
                  <div class="card-body h-100 d-flex flex-column justify-content-center align-items-center">
                    <img class="card-img-top" src={goodz} />
                    <Link type="button" 
                      to={goodzImg.down[index]} target="_blank" download 
                      class="btn mt-2">
                      <img src={download} style={{maxWidth: '20px'}} /></Link>
                  </div>
                </div>
              </div>
            )
          })}
          
  
        </div>
      </div>

    </div>
  );
}

export default Goodz;
