import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Nav from './component/Nav'
import MainSection from './component/MainSection';
import War from './component/War';
import Gender from './component/Gender';
import Animal from './component/Animal';
import Fun from './component/Fun';
import Goodz from './component/Goodz';
import './App.css';
import Axios from 'axios';
import ADDRESS from './constant/httpAdresss';


const App = () => {
  
  useEffect(() => {
    
    Axios.get(ADDRESS.END_POINTS.NUM).then(x => console.log('x', x));

  }); 


  return (
    <div className="App m-0 p-0">
      <Router>
        <div className="container-fluid p-0 m-0">
          <div className="column">

            <div className="col p-0 m-0 nav-fix">
                <Nav />
            </div>

            <div className="col main-section-media m-0 p-0">
              <Switch>
                <Route exact path="/"  component={MainSection}/>
                <Route path="/war" component={War}/>
                <Route path="/gender" component={Gender}/>
                <Route path="/animal" component={Animal}/>
                <Route path="/fun" component={Fun} />
                <Route path="/goodz" component={Goodz} />
              </Switch>
            </div>

            <div className="mt-5">

            </div>

          </div>
        </div>
      </Router>
    </div>  
  );
}

export default App;
