import React, { useState } from 'react';
import Modal from 'react-modal'
import flower from '../asset/imgs/war-flower.PNG';
import redblue from '../asset/imgs/war-redblue.PNG';
import paly from '../asset/imgs/wang/paly.PNG';
import county from '../asset/imgs/war/county.PNG';
import empty from '../asset/imgs/war/empty.PNG';
import flowers from '../asset/imgs/war/flower.PNG';
import gantree from '../asset/imgs/war/gantree.PNG';
import je from '../asset/imgs/war/je.PNG';
import mainM from '../asset/audios/war/4전쟁-1마을소개.mp3';
import flowersM from '../asset/audios/war/4전쟁-박꽃이 피었습니다.mp3';
import emptyM from '../asset/audios/war/4전쟁-빈공장의기타소리.mp3';
import countyM from '../asset/audios/war/4전쟁-빨간나라파란나라.mp3';
import jeM from '../asset/audios/war/4전쟁-제무시.mp3';
import gantreeM from '../asset/audios/war/4전쟁-할아버지의감나무.mp3';
import playAuio from '../func/playAuio';
import wangs from '../constant/wang';



const War = () => {
  playAuio.initAudio();

  const playPause = (linkURl) => {
    playAuio.playPause(linkURl);
  }

  const mpArr = [flowersM, emptyM, gantreeM, jeM, countyM];
  const imgArr = [flowers, empty, gantree, je, county];


  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});



  const openModal = (index) => {
    const simple = [
      '저 멀리 추크섬에 박꽃이 어떻게 피었을까? 일본군의 돈을 벌게해준다는 거짓에 속아 위안부로 끌려간 소녀들의 아픈 이야기, 추크섬의 박꽃 비화에 그 이야기가 숨겨져있다.',
      '그림작가가 우연히 발견한 기타공장, 부당해고를 당하신 분들을 만나 같이 마음을 나누고 생활을 함께하고 때로는 같이 악기 연주도 하며 그들의 투쟁이 시작된 이유를 듣게 되는데..',
      '6.25 전쟁이 끝난 후에도 그 때의 기억을 잊지 못하는, 감나무를 심으며 그 아픔을 풀어나가는  할아버지를 보는 손자의 이야기.',
      '6.25때 제무시에 실어 날라져서 죽을 수 밖에 없었던 마을 사람들. 이 일을 거부한 한 트럭이 언덕으로 굴러 떨어진다. 제무시는 학살이라는 주제를 쉽고 간결하게 전달하지만, 아픈 역사와 개인의 도덕성, 인권의 가치에 대한 이야기를 묵직하게 던지고 있다.',
      '빨간 나라, 파란 나라. 왕들의 강요로 갈라졌지만 이산가족들은 모두 서로가 그리웠다. 결국은 왕들의 밀담이 국민들에게 들키고 마는데…',
    ];
    const bookData= [
      { title:'박꽃이 피었습니다.', name: '이영경/문영숙', work: '위즈덤하우스' },
      { title:'빈 공장의 기타소리', name: '전진경', work: '창비' },
      { title:'할아버지의 감나무', name: '서진선', work: '평화를품은책' },
      { title:'제무시', name: '임경섭', work: '평화를품은책' },
      { title:'빨간나라, 파란나라', name: '에릭바튀', work: '담푸스' },
    ];
    const memory = [
      {one: '그로부터 70여 년이 흐른 지금도 추크섬 우물터에는 순이의 넋인양 박꽃이 절로 피었다가 절로 진대', sceen: '소녀가 보고 싶었던 고향의 꽃을 미처 피우지 못하고 죽은 장면'},
      {one: "우리를 도와줄 줄 알았던 경찰들은 가만히 서있었다.", sceen: '주인공이 이곳에 모인 모두의 이야기를 듣게 되었던 순간'},
      {one: "어머니, 저도 사람을 많이 죽였습니다. 누구에게 어떻게 용서를 구해야 할지 모르겠습니다.", sceen: '문구점에서 손주가 장난으로 겨눈 장난감총을 보고 전쟁이 끝난지 한참이 됐음에도 화들짝 놀라 굳으셨던 할아버지의 모습'},
      {one: '땅에 처박힌 625호는 보았다. 고무신을 신고 숯골이 아닌 마을을 달리는 자신을.', sceen: '다른 의견을 제시하였다고 언덕 아래로 굴러 떨어졌던 제무시 트럭'},
      {one: '앞으로!" 아이들이 외쳤어요. "두개의 벽쪽으로!', sceen: '아이들이 벽너머로 종이비행기를 날려 서로의 안부를 물었던 장면'},
    ];
    const tag = [
      ['박꽃','위안부','전쟁','추크섬','일본','2차세계대전', '가슴아픈'],
      ['부당해고', '기타','끈기','천막안의 용기','실업','노동자와 사용자의 투쟁'],
      ['감나무', '할아버지의 슬픔','감나무속동지들','6.25전쟁','잊을 수 없는', '잊지 못하는 기억'],
      ['트럭', '한국전쟁','6.25', '국민보도연맹', '학살', '사건', '마을사람들'],
      ['왕들', '종이비행기','분단국가', '빨간나라', '파란나라','민주주의', '통일', '아이들', '이산가족', '남북관계'],
    ];
    const feel = [
      [
        {name: '연록', desc: '현재까지도 이어지고 있는 이 일들은 일본은 외면하고 부정하고 있다. 역사는 다시 그 일들이 반복되지 않기 위해 있는 것 이기 때문에 나는 모두가 이 일에 조금 더 관심을 가지고 알아주었으면 한다.'},
        {name: '호두', desc: '어린나이에 멀리 끌려가 부모님도 만나지 못하고 그 무서운 일들을 당했다고 하니 그들이 안쓰러웠다.'},
      ],
      [
        {name: '연록', desc: '실제로 있었던 사례이기도 하고 불과 2년전까지만 해도 이 사건을 법원은 다루고 있었다는 사실을 알고 나니, 나에게도 먼일은 아니겠구나 라는 생각을 하게 되었다'},
        {name: '호두', desc: '모든 분들이 제대로 된 대우를 받으면 좋겠다'},
      ],
      [
        {name: '연록', desc: '전쟁이 끝난지 몇년이 흘러도 생생한 할아버지의 PTSD를 보며 전쟁이 끝나도 그 기억은 끝나지 않고 사람을 괴롭히는구나 하고 느꼈다'},
        {name: '호두', desc: '6.25 참전용사들의 아픔을 알 것만 같다..'},
      ],
      [
        {name: '연록', desc: '제무시가 단순한 트럭이 아니라 사람들에게 어떤 의미인지 알게 되는 책이었다.'},
        {name: '호두', desc: '그깟 민주주의 사회주의가 뭐라고 그 많은 사람들을 죽였을까... 란 생각이 들었고 우리 할아버지할머니도 저 일을 겪었을 것이라 생각하니 무서웠다.'},
      ],
      [
        {name: '연록', desc: '빨강 파랑으로 나뉘어져 서로를 그리워하는게 마치 우리 대한민국을 보는 것 같았다.'},
        {name: '호두', desc: '둘이 왕에 의해 어쩔 수 없이 나누어 졌었지만 아이들이 힘을 합쳐 다시 통일한 것을 보고 우리나라도 통일을 할 수 있을 것이다라는 희망을 가지게 되었다.'},
      ],
    ];
    const initTitle = (<div className="align-content-start">
        <p className="pt-2" style={{fontFamily: 'gmkB', fontSize: '20px', color: '#56515E'}}>{bookData[index].title}</p>
        <p className="m-0" style={{fontFamily: 'notoL', color: '#56515E'}}>{bookData[index].name}</p>
        <p className="m-0" style={{fontFamily: 'notoL', color: '#56515E'}}>{bookData[index].work}</p>
      </div>);
    
    const intiTag = tag[index].map((tags, index) => {
      return (
        <p className="mb-0 pl-2" style={{fontFamily: 'gmkB', fontSize: '20px', color: '#56515E'}}>#{tags}</p>
      );
    });
    const initSimple = (<p className="mb-0" style={{fontFamily: 'notoL', fontSize: '15px', color: '#56515E'}}>{simple[index]}</p>);
    const initFeel = feel[index].map(data => {
      return (
        <div>
          <p className="mb-0 mb-1 pl-4 pr-4" style={{fontFamily: 'gmkB', fontSize: '18x', color: '#56515E'}}>{data.name}</p>
          <p className="mb-1 pl-4 pr-4" style={{fontFamily: 'gmkB', fontSize: '13px', color: '#56515E'}}>{data.desc}</p>
        </div>
      )
    })
    setModalData({
      audioIndex: index,
      srcLink: imgArr[index],
      title: initTitle,
      simple: initSimple,
      memory: memory[index],
      tag: intiTag,
      feel: initFeel
    });
    setIsOpen(true);
  }
 
  const afterOpenModal= () => {
    playAuio.initAudio();
    playAuio.playPause(mpArr[modalData.audioIndex]);
  }
 
  const closeModal = () =>{

    playAuio.playPause();
    setIsOpen(false);
  }

  const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '30%',
      height: '80%',
      outline: 'none',
      // marginRight: '-40%',
      transform: 'translate(-50%, -50%)',
      padding:  '0px',
      // boder: '1px solid #56515E !important'
    }
  };
 

  return (
    <div className="container-fluid p-0">

    {/* <img src={paly} className="playImg-war" style={{maxWidth: '200px'}}/> */}

      <div className="row m-0 p-0" style={{background: '#FBF1EA'}}>
        <div className="col d-flex justify-content-center align-items-center">
          <div className="p-0 m-0 d-flex flex-column justify-content-center align-items-start town-title">
            <img src={redblue} style={{maxWidth: '200px'}}/>
            <p className="m-0 town-desc" 
              style={{fontFamily: 'notoL', color: 'rgb(79 173 103)', fontSize: '30px'}}>
              평화가 곧 길이다, "전쟁 그만 둬!"</p>
            <p className="m-0 town-name" 
              style={{fontFamily: 'gmkB', color: 'rgb(79 173 103)', fontSize: '50px'}}>
              전쟁마을</p>
          </div>
        </div>
        <div className="col">
          <div className="row d-flex flex-column justify-content-center align-items-center p-0">
            <img className="guitar" src={flower} style={{maxWidth: '500px'}}></img>
          </div>
        </div>
      </div>

      <div className="row m-0 p-0 town-middle" style={{background: '#238666', minHeight: '400px'}}>
        <div className="col d-flex justify-content-center align-items-center" >
          <div className="container d-flex flex-column align-items-center justify-content-md-start m-0">
            <div>
              <p className="m-0 town-middle-title" 
                style={{fontFamily: 'gmkB', color: 'rgb(234 236 81)', fontSize: '20px'}}>
                  이 마을은 <br /> 전쟁의 피해자 입장에서 작성된 그림책 마을입니다.
              </p>
              <p className="m-0 town-middle-desc" 
                style={{fontFamily: 'notoL', color: 'white', fontSize: '17px'}}>
                전쟁으로 일상을 잃어 버린 사람들의 이야기를 둘러보세요.
              </p>
            </div>
          </div>
        </div>
        <div className="col town-none">
          <p></p>
        </div>
      </div>

      <div className="row mt-5 m-0 p-0">
        <div className="container d-flex flex-column align-items-center">
          <div className="row m-0 p-0">
            <img className="main-wang" onClick={() => {playPause(mainM)}} src={paly} style={{maxWidth: '200px', cursor: 'pointer'}}/>
          </div>

          <div className="row m-0 p-0">
            <div className="container d-flex align-items-center flex-column">
            <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '30px', color: 'rgb(79 173 103)'}}>전쟁마을 평화책</p>
            <div class="alert alert-danger mt-1" role="alert" 
              style={{padding: '1px 70px 1px 1px', borderColor: 'rgb(79 173 103) !important', borderRadius: '20px'}}></div>
              <p className="w-50" style={{fontFamily: 'notoL', color: 'rgb(79 173 103)'}}>
                비극은 생각이 다를 때 상대를 쉽게 적으로 여기는 일에서 시작됩니다. 일상을 지켜내는 작은 용기가 모이면 전쟁이라는 거대한 비극을 막아볼 수 있지 않을까요? 
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row m-0 p-0">
        
        <div class="container align-items-center">

          <div class="row w-50 m-0 mr-auto ml-auto mt-5 mb-5 town-book-top">
            <div class="col">
              <div className="container d-flex flex-column align-items-center justify-content-md-center m-0">
                <div className="d-flex flex-column align-items-center justify-content-md-center" 
                  style={{border: '1px solid rgb(139 132 179)', padding: '10px 40px 10px 40px', borderRadius: '20px'}}>
                  <img className="mt-3 mb-3" src={flowers} style={{width: '150px' ,height: '180px'}} onClick={()=> openModal(0)}></img>
                  {/* <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '15px', color: 'rgb(79 173 103)'}}>박꽃이 <br /> 피었습니다.</p> */}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
              <div class="col">
                <div className="container d-flex flex-column align-items-end justify-content-md-center town-book">
                <div className="d-flex flex-column align-items-center justify-content-md-center" 
                  style={{border: '1px solid rgb(139 132 179)', padding: '10px 40px 10px 40px', borderRadius: '20px'}}>
                  <img className="mt-3 mb-3" src={empty} style={{width: '150px' ,height: '180px'}} onClick={()=> openModal(1)}></img>
                  {/* <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '15px', color: 'rgb(79 173 103)'}}>빈 공장의 <br />기타 소리</p> */}
                </div>
                </div>
              </div>
              <div class="col">
                <div className="container d-flex flex-column align-items-start justify-content-md-center town-book">
                  <div className="d-flex flex-column align-items-center justify-content-md-center" 
                    style={{border: '1px solid rgb(139 132 179)', padding: '10px 40px 10px 40px', borderRadius: '20px'}}>
                    <img className="mt-3 mb-3" src={gantree} style={{width: '150px', height: '180px'}}
                     onClick={()=> openModal(2)}></img>
                    {/* <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '15px', color: 'rgb(79 173 103)'}}>할아버지의 <br /> 감나무</p> */}
                  </div>
                </div>
              </div>
          </div>

          <div class="row mt-5 town-book-bottom">
              <div class="col">
                <div className="container d-flex flex-column align-items-end justify-content-md-center m-0 town-book">
                  <div className="d-flex flex-column align-items-center justify-content-md-center" 
                    style={{border: '1px solid rgb(139 132 179)', padding: '10px 40px 10px 40px', borderRadius: '20px'}}>
                    <img className="mt-3 mb-3" src={je} style={{width: '150px', height: '180px'}} 
                      onClick={()=> openModal(3)}></img>
                    {/* <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '15px', color: 'rgb(79 173 103)'}}>빨간나라 <br /> 파란나라</p> */}
                  </div>
                </div>
              </div>
              <div class="col">
                <div className="container d-flex flex-column align-items-start justify-content-md-center m-0 town-book">
                  <div className="d-flex flex-column align-items-center justify-content-md-center" 
                    style={{border: '1px solid rgb(139 132 179)', padding: '10px 40px 10px 40px', borderRadius: '20px'}}>
                    <img className="mt-3 mb-3" src={county} style={{width: '150px', height: '180px'}} 
                      onClick={()=> openModal(4)}></img>
                    {/* <p className="m-1" style={{fontFamily: 'gmkB', fontSize: '15px', color: 'rgb(79 173 103)'}}>제무시</p> */}
                  </div>
                </div>
              </div>
          </div>

        </div>

      </div>

      <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          
        >
 
          <div className="container-fluid p-0 m-0 w-100" role="alert" style={{width: '300px'}}>

            <div class="alert alert-dark p-2 m-0" role="alert" style={{background: '#56515E'}}>
            </div>

            <div className="container p-3">

              <div class="row p-0 m-0">
                <div className="col">
                  <img className="col h-100" src={modalData.srcLink} 
                  ></img>
                </div>
                <div className="col d-flex algin-item-start">
                  {modalData.title}
                </div>
                {/* <div className="col p-0 m-0 d-flex  align-items-center justify-content-center">
                  <img className="col pop-img" src={modalData.srcLink} 
                  style={{width: '150px' ,height: '180px'}}
                  ></img>
                </div>
                <div className="col p-0 m-0 d-flex  justify-content-start">
                  {modalData.title}
                </div> */}
                {/* <img className="col-4 pop-img" src={modalData.srcLink} 
                  style={{MaxWidth: '100px', height: '180px'}}></img>
                {modalData.title} */}
              </div>

              <div class="row mt-2 mb-0 pb-0 pl-3 pr-3">
                <div className="col d-flex flex-wrap">
                  {modalData.tag}
                </div>
              </div>

              <div class="row mt-3 pt-0 pl-4 pr-4">
                <div className="col">
                  <p className="mb-0" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#56515E'}}>간략한 한줄 소개</p>
                </div>
              </div>

              <div class="row mt-0 pt-0 pl-4 pr-4">
                <div className="col">
                  {modalData.simple}
                </div>
              </div>

              <div class="row mt-3 pt-0 pl-4 pr-4">
                <div className="col">
                  <p className="mb-0" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#56515E'}}>기억에남는 한줄</p>
                </div>
              </div>

              <div class="row mt-0 pt-0 pl-4 pr-4">
                <div className="col">
                  <p className="mb-0" style={{fontFamily: 'notoL', fontSize: '15px', color: '#56515E'}}>
                    {modalData?.memory?.one}
                  </p>
                </div>
              </div>

              <div class="row mt-3 pt-0 pl-4 pr-4">
                <div className="col">
                  <p className="mb-0" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#56515E'}}>기억에남는 장면</p>
                </div>
              </div>

              <div class="row mt-0 pt-0 pl-2 mb-3 pl-4 pr-4">
                <div className="col">
                  <p className="mb-0" style={{fontFamily: 'notoL', fontSize: '15px', color: '#56515E'}}>
                    {modalData?.memory?.sceen}
                  </p>
                </div>
              </div>

              {/* <div class="row mt-3 pt-0">
                <div className="col-1"></div>
                <div className="col-10">
                  <p className="mb-0" style={{fontFamily: 'gmkB', fontSize: '15px', color: '#56515E'}}>더재미있게 읽는 TIP</p>
                </div>
              </div> */}


            </div>

            <div class="row m-0 p-0 d-flex flex-column" style={{minHeight: '200px'}}>
              <div className="col d-flex align-items-center justify-content-center pt-3">
                <img className="col" src={wangs.data[Math.floor(Math.random() * wangs.data.length)]} style={{ maxWidth: '200px', height: 'auto'}}></img>
              </div>
              <div className="col d-flex align-items-end justify-content-center">
                <p className="pt-2" style={{fontFamily: 'gmkB', fontSize: '20px', color: '#56515E'}}>왕왕이 감상평</p>
              </div>
            </div>

              <div className="container-fluid p-0 m-0 w-100" style={{background: '#FBF1EA'}}>
                <div class="row m-0 p-0 mt-4 pt-4 pb-4">
                  <div className="col">
                    {modalData.feel}
                  </div>
                </div>
              </div>
          </div>

        </Modal>

    </div>
  );
}

export default War;
