import axios from 'axios';
import ADRESS from '../../constant/httpAdresss';

export default {
  getData: async () => {
    try {
      const boardData = await axios.get(ADRESS.END_POINTS.BOARD);
      return boardData;
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  addData: async (data) => {
    console.log(data);
    try {
      const boardData = await axios.post(ADRESS.END_POINTS.BOARD, { board: data });
      return boardData;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  
  deleteData: async () => {}
}
