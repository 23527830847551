import React, { useState } from 'react';
import { Link } from "react-router-dom";
import basic from '../asset/imgs/wang/basic.PNG';
import start from '../asset/imgs/wang/start.PNG';
import how from '../asset/imgs/how.jpg';
import paly from '../asset/imgs/wang/paly.PNG';
import ssum1 from '../asset/imgs/ssum1.jpg';
import ssum2 from '../asset/imgs/ssum2.jpg';
import ssum3 from '../asset/imgs/last.jpg';
import map1 from '../asset/map1.png';
import map2 from '../asset/map2.gif';
import map3 from '../asset/map3.gif';
import map4 from '../asset/map4.gif';
import map5 from '../asset/map5.gif';
import map6 from '../asset/map6.gif';
import map7 from '../asset/map7.gif';
import map8 from '../asset/map8.gif';
import map9 from '../asset/map9.gif';
import mainM from '../asset/audios/mainM.mp3';
import playAuio from '../func/playAuio';

import YouTube from 'react-youtube';

const youtbeOption = {
  height: '500px',
  width: '100%',
}


const MainSection = () => {

  playAuio.initAudio();

  const playPause = () => {
    playAuio.playPause(mainM);
  }
  
  const [youtubeId , setYoutubeId] = useState('fAyqpOvM2iI');

  const changeYoutubeId = (index) => {
    const linkArr = ['','fAyqpOvM2iI', 'EIN9VR3b_oY','uBIKpMY4gNs'];
    setYoutubeId(linkArr[index])
  }
  
  const opacityTownImg = (e) => {
    // console.log(e);
    const targetEl = document.querySelectorAll(e);
    targetEl.forEach(el => el.style.opacity='0.7');
  }

  const opacityDownTownImg = (e) => {
    const targetEl = document.querySelectorAll(e);
    targetEl.forEach(el => el.style.opacity='1');
  }

  return (
    <div className="container-fluid p-0">

      {/* <img src={paly} className="playImg-main" style={{maxWidth: '200px'}}/> */}

      <div className="container-fluid m-0 p-0 w-100" style={{background: '#373543'}}>
        <div className="container" style={{paddingTop: '400px !important'}}>
          <div className="row w-100 m-auto main-img-media" style={{ maxWidth: '800px', minHeight: '800px'}}>

          <div className="w-100">
            <div className="row h-100 w-100 m-0"> 
              <div className="col p-0" onMouseOver={() => opacityTownImg('.pop-1')} onMouseLeave={() => opacityDownTownImg('.pop-1')}>
                <Link to='/war'>
                    <img className="pop-1" src={map1} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} />
                </Link>
              </div>
              <div className="col p-0" onMouseOver={() => opacityTownImg('.pop-2')} onMouseLeave={() => opacityDownTownImg('.pop-2')}>
                <Link to='/gender'>
                  <img className="pop-2" src={map2} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} />
                </Link>
              </div>
              <div className="col p-0" onMouseOver={() => opacityTownImg('.pop-2')} onMouseLeave={() => opacityDownTownImg('.pop-2')} >
              <Link to='/gender'>
                <img className="pop-2" src={map3} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} />
              </Link>
              </div>
            </div>
          </div>

          <div className="w-100">
            <div className="row h-100 w-100 m-0">
              <div className="col p-0" onMouseOver={() => opacityTownImg('.pop-1')} onMouseLeave={() => opacityDownTownImg('.pop-1')}>
                <Link to='/war'>
                  <img className="pop-1" src={map4} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} />
                </Link>
              </div>
              <div className="col p-0" onMouseOver={() => opacityTownImg('.pop-1')} onMouseLeave={() => opacityDownTownImg('.pop-1')}>
                <Link to='/war'>
                  <img className="pop-1" src={map5} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} />
                </Link>
              </div>
              <div className="col p-0" onMouseOver={() => opacityTownImg('.pop-2')} onMouseLeave={() => opacityDownTownImg('.pop-2')}>
                <Link to='/gender'>
                  <img className="pop-2" src={map6} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} />
                </Link>
              </div>
            </div>
          </div>

          <div className="w-100">
            <div className="row h-100 w-100 m-0">
              <div className="col p-0" onMouseOver={() => opacityTownImg('.pop-1')} onMouseLeave={() => opacityDownTownImg('.pop-1')}>
                <Link to='/war'>
                  <img className="pop-1" src={map7} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} />
                </Link>
              </div>
              <div className="col p-0" onMouseOver={() => opacityTownImg('.pop-3')} onMouseLeave={() => opacityDownTownImg('.pop-3')}>
                <Link to='/animal'>
                  <img className="pop-3" src={map8} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} />  
                </Link>
              </div>
              <div className="col p-0" onMouseOver={() => opacityTownImg('.pop-3')} onMouseLeave={() => opacityDownTownImg('.pop-3')}>
                <Link to='/animal'>
                  <img className="pop-3" src={map9} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} />
                </Link>
              </div>
            </div>
          </div>

          </div>

          <div className="row mt-5 mb-5">
            <div className="container d-flex align-items-center flex-column">

              <div className="row">
                <img className="main-wang" src={start} style={{maxWidth: '150px'}}/>
              </div>

              <div className="row mt-5" style={{ maxWidth: '65%' }}>
                <p style={{fontFamily: 'gmkB', color: '#F2C0BD', fontSize: '20px'}}>안녕하세요<br /> 
                저희는 평화지도 큐레이터 왕왕입니다.
                </p>
                <p className="p-desc-media" style={{fontFamily: 'notoL', color: 'white'}}>저희 왕왕이들은 2020년 5월부터 9월까지 함께 평화책을 읽으며 온라인 전시를 기획했습니다.<br />
                왕왕이들은 모두 3팀으로 나눠 각각의 주제를 가지고 소개글을 작성하고, <br />
                책에 대한 이야기도 나누며 이번 평화지도를 작업했습니다. <br />
                코로나19로 인하여 계획했던 것과 달리 온라인 전시회를 열게 되어 많은 우여곡절을 겪었지만, <br />
                꾸준하게 열심히 준비해서 오늘의 이 전시를 만들 수 있었습니다. <br />
                평화지도가 여러분들에게 전쟁, 동물권, 젠더 <br />
                그리고 이 모든 걸 포함하는 평화에 대해 생각하는 유익한 전시이기를 바랍니다. <br />
                전시에 시간 내어 찾아와 주셔서 감사합니다.
                </p>
              </div>

            </div>
          </div>

          <div class="alert alert-danger w-100" role="alert" style={{margin: '13% 0 13% 0', padding: '1px 20px 1px 1px', borderColor: '#B5B9D1 !important'}}></div>

          <div className="row mt-5">
          <div className='container-fluid mb-1'>

            <YouTube className="youtube-media" videoId={youtubeId} opts={youtbeOption} />

            <div class="row row-cols-1 row-cols-md-3 mt-5 mb-5">
              <div class="col mb-4 h-100">
                <div class="card sumnail">
                  <img src={ssum1} 
                    onClick={() => changeYoutubeId(1)}
                    style={{cursor: 'pointer'}}
                    class="card-img-top" alt="..." />
                </div>
              </div>
              <div class="col mb-4">
                <div class="card sumnail" style={{borderColor: '#373543 !important'}}>
                  <img src={ssum2} 
                    onClick={() => changeYoutubeId(2)}
                    style={{cursor: 'pointer'}}
                    class="card-img-top" alt="..." />
                </div>
              </div>
              <div class="col mb-4">
                <div class="card sumnail" style={{borderColor: '#373543 !important'}}>
                  <img src={ssum3} 
                    onClick={() => changeYoutubeId(3)}
                    style={{cursor: 'pointer'}}
                    class="card-img-top" alt="..." />
                </div>
              </div>
            </div>

            {/* <div className="mt-5 youtube-media-prepare">
            <div className="card-group">
                <div className="card" onClick={() => changeYoutubeId(1)} >
                  <div className="card-body p-0 m-0 w-100 h-100">
                    <img src={map1} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                    className="card-img-top w-100 h-100" />
                  </div>
                </div>
                <div className="card" onClick={() => changeYoutubeId(2)} >
                  <img src={map1} 
                  className="card-img-top" alt="..." 
                  style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} />
                  <div className="card-body">
                  </div>
                </div>
                <div className="card" onClick={() => changeYoutubeId(3)} >
                  <img src={map1} 
                  className="card-img-top" alt="..." 
                  style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} />
                  <div className="card-body">
                  </div>
                </div>
              </div>
            </div> */}

          </div>
          </div>
        </div>
      </div>
      
      <div className="container-fluid m-0 pt-5" style={{background: 'white'}}>
        
        <div className="container mt-5">
          
        <div class="row mb-5">
          <div className='col'>
          <div className='col'>
            <div class="col">
              <div class="col pr-5 col-dosont">
                <div class="container p-0">

                  <div class="row p-0">
                    <div class="col d-flex align-items-center justify-content-center p-0">
                      <img src={paly} onClick={playPause} style={{maxWidth: '250px', cursor: 'pointer'}}/>
                      <p className="m-0" 
                        
                        style={{fontFamily: 'gmkb', fontSize: '30px'}}>도슨트<br />안내</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col d-flex justify-content-center">
                      <p style={{fontFamily: 'notoL', fontSize: '15px', maxWidth: '400px'}}>
                        도슨트는 왕왕이들의 음성으로 제공 됩니다. 곳곳에 플레이 버튼을 들고 있는 왕왕이가 보일 거예요. 여기 있는 왕왕이부터 클릭해 보세요!
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          </div>
          <div className='col'>
            <div class="col">
              <div class="col pr-5 col-dosont">
                <div class="container p-0">

                  <div class="row p-0">
                    <div class="col d-flex align-items-center justify-content-center p-0">
                      <img src={basic} style={{maxWidth: '250px'}}/>
                      <p className="m-0" style={{fontFamily: 'gmkb', fontSize: '30px'}}>전시회<br />안내</p>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col d-flex justify-content-center">
                      <p style={{fontFamily: 'notoL', fontSize: '15px', maxWidth: '400px'}}>
                      저희 전시는 평화지도 라는 컨셉에 맞추어 15권의 책을 소개 해 드립니다. 메인페이지 지도에서 각각의 마을로 평화지도를 따라 평화여정을 떠나보세요! 
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        

        {/* <div class="alert alert-danger w-100" role="alert" style={{margin: '1% 0 1% 0', padding: '1px 20px 1px 1px', borderColor: '#B5B9D1 !important'}}></div> */}

        <div className="row pt-5">
              <div className="container-fluid">
                <div className="row link-people">

                  <div className="col">
                    <p style={{fontFamily: 'gmkb', fontSize: '20px'}}>전시를 함께 만든 사람들</p>
                    <p style={{fontFamily: 'notoL', lineHeight: '25px'}}>
                      [큐레이터 왕왕] <br />
                      
                      기획팀 | 김시연(하지), 김예인(룬), 어원주(연록), 이상혁(깁스), 이서영(스티), 한예림(방울) <br />
                      굿즈제작 | 김성윤(호두), 이보영(뽀) <br />
                      도슨트 글 | 이상혁(깁스), 이서영(스티) <br />
                      도슨트 목소리 | 김시연(하지), 김예인(룬), 어원주(연록), 이서영(스티), 한예림(방울) <br />
                      전시 구성 | 어원주(연록), 한예림(방울) <br />
                      브로슈어기획 | 김시연(하지), 김예인(룬) <br />

                      <br />
                      [도우미] <br />
                      
                      김가현(한다), 김성현(토리), 허영찬(찬쓰) <br />
                      시각디자인 | 백소민(나무) <br />
                      영상작업 | 차운호(차차) <br />
                    </p>

                  </div>
                  
                  <div className="col d-flex align-items-center justify-content-center link-img">
                      <img src={how} 
                        onClick={(e) => {window.open("https://forms.gle/bgMML5Q4KFCXWzS58")}}
                        style={{maxWidth: '200px', maxHeight: 'auto', cursor: 'pointer'}}/>
                  </div>

                </div>
              </div>

        </div>

        <div class="alert alert-danger w-100" role="alert" style={{margin: '3% 0 3% 0', padding: '1px 20px 1px 1px', borderColor: '#B5B9D1 !important'}}></div>

          <div className="row">
            <div className="container d-flex align-items-center flex-column">
              <div className="row">
              <p style={{fontFamily: 'gmkb', fontSize: '20px'}}>하자센터</p>
              </div>

              <div className="row pt-2">
                <div className="container d-flex align-items-center flex-column">
                  <p className="m-0 p-0" style={{fontFamily: 'notoL'}}>
                  (07244) 서울특별시 영등포구 영신로 200 서울시립청소년직업체험센터(하자센터)
                  </p>
                  <p
                  className="m-0 p-0" style={{fontFamily: 'notoL'}}>02-2677-9200</p>
                  <p onClick={() => {window.open('https://haja.net')}}
                  className="m-0 p-0" style={{fontFamily: 'notoL', cursor: 'pointer'}}>https://haja.net</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


    </div>
  );
}

export default MainSection;
