let audio = new Audio();

export default {
  playPause: (url) => {
    if(audio.paused){
      audio.src = url;
      audio.play();
    } else {
      audio.pause();
      audio.currentTime=0;
    }
  },

  initAudio: () => {
    if(audio.paused){
      audio.src = '';
    } else {
      audio.pause();
      audio.currentTime=0;
    }
  }
}